import { isDefined } from "@/utilities/validation";

export function isBusinessInstallmentsApiFeatureEnabled(): boolean {
    return isDefined(import.meta.env) &&
        isDefined(
            import.meta.env.VITE_BUSINESS_INSTALLMENTS_API_FEATURE_ENABLED,
        )
        ? JSON.parse(
              import.meta.env.VITE_BUSINESS_INSTALLMENTS_API_FEATURE_ENABLED,
          )
        : false;
}

export function isBusinessAlternateGraphFeatureEnabled(): boolean {
    return isDefined(import.meta.env) &&
        isDefined(import.meta.env.VITE_BUSINESS_ALTERNATE_GRAPH_FEATURE_ENABLED)
        ? JSON.parse(
              import.meta.env.VITE_BUSINESS_ALTERNATE_GRAPH_FEATURE_ENABLED,
          )
        : false;
}

export function isBusinessDotNetLoginApiFeatureEnabled(): boolean {
    return isDefined(import.meta.env) &&
    isDefined(
        import.meta.env.VITE_BUSINESS_DOT_NET_LOGIN_API_FEATURE_ENABLED,
    )
        ? JSON.parse(
            import.meta.env.VITE_BUSINESS_DOT_NET_LOGIN_API_FEATURE_ENABLED,
        )
        : false;
}
