export default {
    aansluiting: {
        verbruiksprofielTooltip:
            "Met behulp van het verbruiksprofiel kunnen we het jaarverbruik inschatten van een aansluiting.\u000dDeze schatting is gebaseerd op verbruiksgegevens van kortere periodes en bestaat uit een reeks landelijk vastgelegde fracties (delen).\u000dDeze fracties vormen het verloop van het totale jaarverbruik per aansluiting.",
        leveringsrichtingTooltip:
            "Via een aansluiting kan stroom geleverd worden, maar ook teruggeleverd worden via opwekking van stroom door bijvoorbeeld zonnepanelen.\u000dDit zijn twee verschillende leveringsrichtingen. Ook is een combinatie mogelijk, waarbij beide richtingen aanwezig zijn.",
        verblijfsfunctieTooltip:
            "We spreken van een verblijfsfunctie als het gaat om een pand waarin mensen kunnen verblijven, zoals een huis of kantoorpand.\u000dOnder aansluitingen zonder verblijfsfunctie vallen bijvoorbeeld parkeergarages, garageboxen en openbare verlichting.\u000dEen aansluiting met een verblijfsfunctie komt in aanmerking voor de heffingskorting.",
        profielcategorieUitleg: {
            E1A: "Elektrisch; enkeltarief voor afnemers met een doorlaatwaarde kleiner of gelijk aan 3x 25 Ampere",
            E1B: "Elektrisch; dubbeltarief nachtstroom voor afnemers met een doorlaatwaarde kleiner of gelijk aan 3x 25 Ampere",
            E1C: "Elektrisch; dubbeltarief avondstroom voor afnemers met een doorlaatwaarde kleiner of gelijk aan 3x 25 Ampere",
            E2A: "Elektrisch; enkeltarief voor afnemers met een doorlaatwaarde boven 3x 25 Ampere tot en met 3x 80 Ampere",
            E2B: "Elektrisch; dubbeltarief voor afnemers met een doorlaatwaarde boven 3x 25 Ampere tot en met 3x 80 Ampere",
            E3A: "Elektrisch; afnemers met een doorlaatwaarde boven 3x 80 Ampere, niet voorzien van een continumeting conform de meetcode en een bedrijfstijd kleiner of gelijk 2000 uur",
            E3B: "Elektrisch; afnemers met een doorlaatwaarde boven 3x 80 Ampere, niet voorzien van een continumeting conform de meetcode en een bedrijfstijd groter dan 2000 maar kleiner of gelijk aan 3000 uur",
            E3C: "Elektrisch; afnemers met een doorlaatwaarde boven 3x 80 Ampere, niet voorzien van een continumeting conform de meetcode en een bedrijfstijd groter dan 3000 maar kleiner dan 5000 uur",
            E3D: "Elektrisch; afnemers met een doorlaatwaarde boven 3x 80 Ampere, niet voorzien van een continumeting conform de meetcode en een bedrijfstijd groter of gelijk aan 5000 uur",
            G1A: "Aardgas; standaardjaarverbruik minder dan 5000 m3 en meter G6 of kleiner",
            G2A: "Aardgas; standaardjaarverbruik tot 170.000 m3 en profielbedrijfstijd kleiner dan 750 uur",
            G2B: "Aardgas; standaardjaarverbruik tot 170.000 m3 en profielbedrijfstijd groter of gelijk aan 750 maar kleiner dan 1500 uur",
            G2C: "Aardgas; standaardjaarverbruik tot 170.000 m3 en profielbedrijfstijd groter of gelijk aan 1500 uur",
        },
    },
    connections: {
        connections: "Aansluitingen",
        ean: "EAN",
        retrieving: "{msg} worden opgehaald",
        nodata: "Geen aansluitingen gevonden",
        noAddress: "Geen adresgegevens bekend voor deze aansluiting",
        errormessage:
            "Er is iets misgegaan bij het ophalen van de aansluitingen.",
        filteron: "Filteren op {type}",
        description:
            "Wilt u meer inzicht in aansluitgegevens, eigenschappen van een aansluiting, locatie en het bijhorend contract? Selecteer de gewenste EAN-code om meer informatie te tonen. U kunt hieronder ook eenvoudig zoeken of gegevens filteren.",
    },
    connection: {
        aantal_telwielen: "# telwielen",
        addproperty: "Voeg eigenschap toe",
        address: "Adres",
        belasting_cluster: "Belastingcluster",
        capacity: "Capaciteit",
        connectioninformation: "Aansluitgegevens",
        consumptionsegment: "Verbruikssegment",
        contract_eigenschappen: "Contracteigenschappen",
        contractcapaciteit: "Contractcapaciteit",
        counter: "Telwerken",
        datereceived: "Datum ontvangen",
        deleteproperty: "Verwijder eigenschap",
        description:
            "Wilt u meer inzicht in aansluitgegevens, eigenschappen van een aansluiting, locatie en het bijhorend contract? Selecteer de gewenste EAN-code om meer informatie te tonen. U kunt hieronder ook eenvoudig zoeken of gegevens filteren.",
        downloadexcel: "Download",
        duration: "Looptijd",
        einddatum: "Einddatum",
        eindstand: "Eindstand",
        electricity: "Stroom",
        endingdate: "Einddatum",
        filteron: "Filteren op {type}",
        fysieke_status: "Fysieke status",
        gas: "Gas",
        gebruik_aansluiting: "Gebruik aansluiting",
        grootboekrekening: "Grootboekrekening",
        grootverbruik: "Grootverbruik",
        huidige_interval: "Huidige uitleesfrequentie",
        interval: "Gewenste uitleesfrequentie",
        kleinverbruik: "Kleinverbruik",
        kostenplaats: "Kostenplaats",
        laadpaal: "Laadpaal",
        leveringsrichting: "Leveringsrichting",
        meetrichting: "Meetrichting",
        metering_type: "Type aansluiting",
        meternumber: "Meternummer",
        networkoperator: "Netbeheerder",
        networkoperatorean: "Netbeheerder EAN",
        nodata: "Geen aansluitingen gevonden",
        offpeaksjv: "SJV Laag",
        omschrijving: "Omschrijving",
        peaksjv: "SJV Normaal",
        product: "Product",
        profilecode: "Profielcategorie",
        properties: "Eigenschappen",
        propertyfield: "Eigenschapveld",
        propertyvalue: "Eigenschapwaarde",
        propositie: "Propositie",
        proposition: "Propositie",
        readmeterpermission: "Machtiging sturen",
        retrieving: "{msg} worden opgehaald",
        returndelivery: "Teruglevering",
        returnpeaksjv: "Teruglevering Normaal",
        returnoffpeaksjv: "Teruglevering Laag",
        returnsinglesjv: "Teruglevering Enkel",
        save_uitleesfrequentie: "Opslaan",
        singlesjv: "SJV Enkel",
        sjv: "Standaard jaarverbruik (SJV)",
        smartmeter: "Slimme meter",
        startingdate: "Begindatum",
        status: "Status",
        tariff: "Tarief",
        tarrifs: "Tarieven",
        telwerkidentificatie: "Identificatie",
        typemeter: "Type meter",
        uitleesfrequentie: "Uitlezen slimme meter",
        uitleesfrequentie_description:
            "Hier kunt u de uitleesfrequentie van uw slimme meter wijzigen.",
        uitleesfrequentie_title: "Uitlezen slimme meter",
        verblijfsfunctie: "Verblijfsfunctie",
        wijzebemetering: "Wijze van bemetering",
    },
};
